* {
  box-sizing: border-box;
}

body {
  background-color: #d6d8da;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #d6d8da;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.feather:hover {
  cursor: pointer;
  fill: #19c679;
}